import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CatcherService {

  constructor(private http: HttpClient) {
  }

  uploadCompressedSnapshot(base64Image: string, pWidth: number, pHeight: number, id: string) {
    const arr = {
      image: base64Image,
      width: pWidth,
      height: pHeight,
      photoId: id
    };

    // FIXME: url in eviroment-config-file
    return this.http.post('https://api.photowall.events/v1/photos/compressed', JSON.stringify(arr), {
      reportProgress: true,
      observe: 'events',
      headers: new HttpHeaders({
        id_token: localStorage.getItem('che-token')
      })
    });
  }

  uploadSnapshot(base64Image: string, id: string, pFileType: string) { // FIXME: Filetype aus base auslesen im Backend?
    const arr = {
      photoId: id,
      original: {
        image: base64Image,
        fileType: pFileType
      }
    };

    return this.http.post('https://api.photowall.events/v1/photos', JSON.stringify(arr), {
      reportProgress: true,
      observe: 'events',
      headers: new HttpHeaders({
        id_token: localStorage.getItem('che-token')
      })
    });
  }

  updateStatus(id: string, pStatus: string) {
    const arr = {
      photoId: id,
      status: pStatus
    };

    return this.http.post('https://api.photowall.events/v1/photos', JSON.stringify(arr), {
      reportProgress: true,
      observe: 'events',
      headers: new HttpHeaders({
        id_token: localStorage.getItem('che-token')
      })
    });
  }
}
