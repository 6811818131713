import {Component, OnInit} from '@angular/core';
import {AuthService} from './services/auth.service';
import {DecorationService} from './services/decoration.service';
import {ActivatedRoute} from '@angular/router';

enum State {
  error, login, catcher
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private state: State = State.login;

  constructor(private authService: AuthService,
              private decorationService: DecorationService,
              private route: ActivatedRoute) {
    this.authService.token.subscribe(value => {
      this.decorationService.isHeaderVisible = true;
      this.state = value == null ? State.login : State.catcher;
    });

    this.route.queryParams.subscribe(params => {
      if (params.eventId && (this.authService.token.value == null || params.eventId !== this.authService.token.value.eventId)) {
        this.authService.login(params.eventId);
      }
    });
  }

  ngOnInit() {
  }

  public isError(): boolean {
    return this.state === State.error;
  }

  public isLogin(): boolean {
    return this.state === State.login;
  }

  public isCatcher(): boolean {
    return this.state === State.catcher;
  }

  public hasHeader(): boolean {
    return this.decorationService.isHeaderVisible;
  }

  public isMobileDevice(): boolean {
    return this.decorationService.isMobileDevice();
  }
}
