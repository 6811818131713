import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CatcherComponent} from './catcher.component';
import {PhotoComponent} from './photo/photo.component';
import {SelectionComponent} from './selection/selection.component';
import {UiModule} from '../ui/ui.module';
import {HttpClientModule} from '@angular/common/http';
import {CatcherService} from './catcher.service';

@NgModule({
  declarations: [
    CatcherComponent,
    PhotoComponent,
    SelectionComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    UiModule
  ],
  providers: [
    CatcherService
  ],
  exports: [
    CatcherComponent
  ]
})
export class CatcherModule {
}
