import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {JwtHelperService} from '@auth0/angular-jwt';

const helper = new JwtHelperService();

export class SnapeventToken {
  public eventId: string;
  public userId: string;
  public title: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly _decodedToken: BehaviorSubject<SnapeventToken> = new BehaviorSubject<SnapeventToken>(null);

  constructor(private http: HttpClient) {
  }

  public login(pEventId: string): Observable<boolean> {
    if (!pEventId) {
      return new BehaviorSubject(false);
    }

    const subject = new Subject<boolean>();

    this.http.post('https://api.photowall.events/v1/token', { // FIXME: environment.endpoint
      app: 'cheese',
      eventId: pEventId,
    }).subscribe((val) => {
      const decoded = helper.decodeToken(val as string);

      const snapeventToken = new SnapeventToken();
      snapeventToken.eventId = decoded.sub;
      snapeventToken.userId = decoded.userId;
      snapeventToken.title = decoded.title;

      localStorage.setItem('che-token', val as string);
      this._decodedToken.next(snapeventToken);
      subject.next(true);
    }, (error) => {
      localStorage.removeItem('che-token');
      this._decodedToken.next(null);
      console.log('error', error);
      subject.next(false);
    });

    return subject.asObservable();
  }

  get token(): BehaviorSubject<SnapeventToken> {
    return this._decodedToken;
  }
}
