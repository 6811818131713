import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'che-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.scss']
})
export class PhotoComponent implements OnInit {

  @Output() event = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  public takePhoto(event): void {
    this.event.emit(event);
  }
}
