import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HttpEventType} from '@angular/common/http';
import {CatcherService} from '../catcher.service';
import * as uuid from 'uuid';

export class SelectionChange {
  source: SelectionComponent;
  state: SelectionState;
}

export enum SelectionState {
  select, abort, upload, finish
}

@Component({
  selector: 'che-selection',
  templateUrl: './selection.component.html',
  styleUrls: ['./selection.component.scss']
})
export class SelectionComponent implements OnInit {
  @Output() public readonly change: EventEmitter<SelectionChange> = new EventEmitter<SelectionChange>();
  @Input() public inputFile;

  public uploadProgress: number = 0;
  private _state: SelectionState = SelectionState.select;
  private loadedUncompressed: number = 0;
  private totalUncompressed: number = 0;
  private loadedCompressed: number = 0;
  private totalCompressed: number = 0;

  private id;

  constructor(private catcherService: CatcherService) {
  }

  ngOnInit() {
    this.uploadProgress = 0;
    this.loadedUncompressed = 0;
    this.totalUncompressed = 0;
    this.loadedCompressed = 0;
    this.totalCompressed = 0;

    this._state = SelectionState.select;
    this.id = uuid.v4(); // FIXME: Move to backend

    if (this.inputFile.target.files && this.inputFile.target.files[0]) {
      const reader = new FileReader();
      const file = this.inputFile.target.files[0];

      reader.onload = ((e) => {
        /* tslint:disable */
        // Downsize-Upload
        const img: HTMLImageElement = new Image();
        img.onload = (() => {
          const canvas = document.createElement('canvas');

          let scale = this.determineScale(img.width, img.height);
          canvas.width = img.width / scale;
          canvas.height = img.height / scale;

          this.getOrientation(reader, (e) => {
            this.fixOrientation(canvas, e);
          });

          canvas.getContext('2d').drawImage(img, 0, 0, canvas.width, canvas.height);
          let base = canvas.toDataURL('image/jpeg', 0.75);

          this.catcherService.uploadCompressedSnapshot(base, canvas.width, canvas.height, this.id).subscribe((event) => {
            if (event.type === HttpEventType.UploadProgress) {
              this.totalCompressed = event.total;
              this.loadedCompressed = event.loaded;
              this.updateProgressBar();
            }
          });

          const fileExtension = this.inputFile.target.files[0].name.split('.').pop();
          this.catcherService.uploadSnapshot(e.target['result'], this.id, fileExtension).subscribe((event) => {
            if (event.type === HttpEventType.UploadProgress) {
              this.totalUncompressed = event.total;
              this.loadedUncompressed = event.loaded;
              this.updateProgressBar();
            }
          });

          // FIXME: Image - Originalgröße anzeigen?
          const image: HTMLImageElement = <HTMLImageElement> document.getElementById('photo');
          image.src = canvas.toDataURL();
        });
        img.src = URL.createObjectURL(file);

        /* tslint:enable */
      });

      reader.readAsDataURL(file);
    }
  }

  public abortSelection(): void {
    this.state = SelectionState.abort;
  }

  public submitSelection(): void {
    // FIXME: Upload bestätigen
    this.state = SelectionState.upload;
  }

  public isSubmitVisible() {
    return this.state === SelectionState.select;
  }

  public isAbortVisible() {
    return this.state === SelectionState.select;
  }

  public isUploadProcessVisible() {
    return this.state === SelectionState.upload;
  }

  set state(newState: SelectionState) {
    const oldState = this._state;
    this._state = newState;

    if (oldState === SelectionState.select && (newState === SelectionState.upload || newState === SelectionState.abort)) {
      this.catcherService.updateStatus(this.id, this.state === SelectionState.abort ? 'abort' : 'submit').subscribe((event) => {
      });
    }

    this.emitChangeEvent();
    this.updateProgressBar();
  }

  get state(): SelectionState {
    return this._state;
  }

  private updateProgressBar() {
    if (this.totalCompressed > 0 && this.totalUncompressed > 0) {
      const loaded: number = this.loadedCompressed + this.loadedUncompressed;
      const total: number = this.totalCompressed + this.totalUncompressed;
      this.uploadProgress = Math.floor(loaded / total * 100);
    }

    if (this.state === SelectionState.upload && this.uploadProgress === 100) {
      this.state = SelectionState.finish;
      // FIXME: Trigger DB-Eintrag anlegen, um Datei anzuzeigen.
    }
  }

  private emitChangeEvent(): void {
    const selectionChange: SelectionChange = new SelectionChange();
    selectionChange.source = this;
    selectionChange.state = this.state;
    this.change.emit(selectionChange);
  }

  // FIXME: Algorithmus verifizieren ... (durch Unit-Test)
  private determineScale(width: number, height: number): number {
    const reference: number = width > height ? width : height;
    return (Math.floor(reference / 1000) + 1);
  }

  // FIXME: Cleanup EXIF ...
  private fixOrientation(canvas: HTMLCanvasElement, orientation: number) {
    const ctx = canvas.getContext('2d');

    switch (orientation) {
      case 2:
        ctx.transform(-1, 0, 0, 1, canvas.width, 0);
        break;
      case 3:
        ctx.transform(-1, 0, 0, -1, canvas.width, canvas.height);
        break;
      case 4:
        ctx.transform(1, 0, 0, -1, 0, canvas.height);
        break;
      case 5:
        ctx.transform(0, 1, 1, 0, 0, 0);
        break;
      case 6:
        ctx.transform(0, 1, -1, 0, canvas.height, 0);
        break;
      case 7:
        ctx.transform(0, -1, -1, 0, canvas.height, canvas.width);
        break;
      case 8:
        ctx.transform(0, -1, 1, 0, 0, canvas.width);
        break;
      default:
        break;
    }
  }

  private getOrientation(reader: FileReader, callback) {
    const view = new DataView(this.base64ToArrayBuffer(reader.result));

    if (view.getUint16(0, false) != 0xFFD8) {
      return callback(-2);
    }

    const length = view.byteLength;
    let offset = 2;

    while (offset < length) {
      if (view.getUint16(offset + 2, false) <= 8) {
        return callback(-1);
      }
      let marker = view.getUint16(offset, false);
      offset += 2;

      if (marker == 0xFFE1) {
        if (view.getUint32(offset += 2, false) != 0x45786966) {
          return callback(-1);
        }

        let little = view.getUint16(offset += 6, false) == 0x4949;
        offset += view.getUint32(offset + 4, little);
        let tags = view.getUint16(offset, little);
        offset += 2;
        for (let i = 0; i < tags; i++) {
          if (view.getUint16(offset + (i * 12), little) == 0x0112) {
            return callback(view.getUint16(offset + (i * 12) + 8, little));
          }
        }
      } else if ((marker & 0xFF00) != 0xFF00) {
        break;
      } else {
        offset += view.getUint16(offset, false);
      }
    }
    return callback(-1);
  }

  private base64ToArrayBuffer(base64) {
    base64 = base64.replace(/^data\:([^\;]+)\;base64,/gmi, '');
    var binaryString = atob(base64);
    var len = binaryString.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }
}
