import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import {CatcherModule} from './catcher/catcher.module';
import {ErrorModule} from './error/error.module';
import {LoginModule} from './login/login.module';
import {HeaderModule} from './header/header.module';
import {AuthService} from './services/auth.service';
import {DecorationService} from './services/decoration.service';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    RouterModule.forRoot([]),
    BrowserModule,
    HttpClientModule,
    CatcherModule,
    ErrorModule,
    LoginModule,
    HeaderModule
  ],
  providers: [
    AuthService,
    DecorationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
