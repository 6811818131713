import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'che-svg-button',
  templateUrl: './svg-button.component.html',
  styleUrls: ['./svg-button.component.scss']
})
export class SvgButtonComponent implements OnInit {
  @Output() change = new EventEmitter();
  @Output() click = new EventEmitter();
  @Input() type: string = 'button';
  @Input() width: number = 11;
  @Input() height: number = 11;

  constructor() { }

  ngOnInit() {
  }

  public emitChange(event): void {
    this.change.emit(event);
  }

  public emitClick(): void {
    this.click.emit();
  }
}
