import {Component, OnInit} from '@angular/core';
import {AuthService} from '../services/auth.service';

@Component({
  selector: 'che-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private authService: AuthService) {
  }

  ngOnInit() {
  }

  public qrlogin(event: string): void {
    if (event.startsWith('photowall.events')) {
      const data: string = event.substring('photowall.events'.length);
      this.authService.login(data);
    }
  }
}
