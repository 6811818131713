import {Component, OnInit} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {DecorationService} from '../services/decoration.service';

@Component({
  selector: 'che-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public title: string;

  constructor(private authService: AuthService,
              private decorationService: DecorationService) {
  }

  ngOnInit() {
    this.authService.token.subscribe(snapevent => {
      this.title = snapevent != null ? snapevent.title : 'QR-Login';
    });
  }

  public isMobileDevice(): boolean {
    return this.decorationService.isMobileDevice();
  }

  public isOrientationPortrait(): boolean {
    return this.decorationService.isOrientationPortrait();
  }

  public isOrientationLandscape(): boolean {
    return this.decorationService.isOrientationLandscape();
  }
}
