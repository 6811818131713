import {Component, OnInit} from '@angular/core';
import {DecorationService} from '../services/decoration.service';
import {SelectionChange, SelectionState} from './selection/selection.component';

enum State {
  photo, selection
}

@Component({
  selector: 'che-catcher',
  templateUrl: './catcher.component.html',
  styleUrls: ['./catcher.component.scss']
})
export class CatcherComponent implements OnInit {

  public state: State = State.photo;
  private inputFile;

  constructor(private decorationService: DecorationService) {
  }

  ngOnInit() {
  }

  private reset(): void {
    this.state = State.photo;
    this.decorationService.isHeaderVisible = true;
    this.inputFile = undefined;
  }

  public isPhoto(): boolean {
    return this.state === State.photo;
  }

  public isSelection(): boolean {
    return this.state === State.selection;
  }

  public selectPhoto(inputFile): void {
    this.state = State.selection;
    this.decorationService.isHeaderVisible = false;
    this.inputFile = inputFile;
  }

  public changeSelection(event: SelectionChange): void {
    if (event.state === SelectionState.abort || event.state === SelectionState.finish) {
      this.reset();
    }
  }
}
